import { Alert, Box, Chip, Link, Skeleton, styled, Typography } from "@mui/material";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import CabinetPage from "../../components/Common/CabinetPage";
import { 
  BookingSlot, BookingSlotsByDay, ExternalMeetingInfo, FetchReturn, MeetingQuestionAnswer, 
  MeetingQuestionAnswerSubmission, NormalizedExternalParticipant
} from "../../store";
import { SidePanel } from "../../components/Meeting/BookingSidePanel";
import { getTemplateVars, humanReadableDuration, replaceTemplateVariables } from "../../utils/scheduleUtils";
import colors from "../../colors";
import { CabTextTokenDisplay } from "@CabComponents/CabTextTokenDisplay";
import DateTimeSelection from "./DateTimeSelection";
import MobileDateTimeSelection from "./MobileDateTimeSelection";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import AttendeeInfo from "./AttendeeInfo";
import Confirmation from "./Confirmation";
import PollResponseCTA from "../../components/Meeting/PollResponseCTA/PollResponseCTA";
import { EVENT_TYPE, PAGE_URL } from "../../constants";
import MeetingCancelation from "../../components/Meeting/MeetingCancelation/MeetingCancelation";
import { useElementBreakpoint } from "../../utils/hooks";
import { DateTime } from "luxon";
import NoTimesWork from "./NoTimesWork/NoTimesWork";
import { NAVY_LOGO_SRC } from "../../resourceUrls";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoArrowBack, IoEyeOffOutline, IoEyeOutline, IoTimeOutline } from "react-icons/io5";
import { Link as RouterLink } from 'react-router-dom';
import { PublicOrganization } from "../../store/cabinetApi/generated/core";


export type Props = {
  step: number;
  meetingInfo?: ExternalMeetingInfo;
  isAuthenticated: boolean;
  isBooking: boolean;
  isPreview?: boolean;
  isReschedulingURL?: boolean;
  allowAddParticipants: boolean;
  confirmed: boolean;
  isBadResponse: boolean;
  isSelectedDay?: boolean;
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  slotsByDay: BookingSlotsByDay;
  setSlotSelected: (slot: BookingSlot) => void;
  setStep: (step: number) => void
  onConfirm: () => void;
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  fetchMeetingQuestionAnswers: (externalId?: string) => Promise<{
    answers: {[id: number]: MeetingQuestionAnswer}} | undefined>
  prefillName?: string;
  prefillEmail?: string;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void;
  loading: boolean;
  defaultYearMonth: [number, number]|null;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
  viewDate: DateTime;
  organization?: PublicOrganization;
  isEnterpriseBook?: boolean;
  setNoTimesWork: (value: boolean) => void;
  submitNoTimesWorkForm: (data: NormalizedExternalParticipant) => void;
  noTimesWork: boolean;
  noTimesWorkSent: boolean;
  loadingPage: boolean;
};

export const BookMeeting = ({
  meetingInfo, selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, step, slotSelected, isPreview, 
  isReschedulingURL, isBooking, setCurrentTimeZone, handleDaySelected, setSlotSelected, onConfirm, setStep, isFramed,
  handleScheduleMeeting, fetchMeetingQuestionAnswers, isAuthenticated, allowAddParticipants, confirmed, isBadResponse,
  isSelectedDay, prefillEmail, prefillName, onCalendarDateChange, loading, defaultYearMonth, rebookParticipants, 
  rebookAnswers, viewDate, organization, isEnterpriseBook, setNoTimesWork, submitNoTimesWorkForm, noTimesWork,
  noTimesWorkSent, loadingPage
}: Props): ReactElement => {

  const [drawerWidth, setDrawerWidth] = useState(Math.max(300, (window.innerWidth * 0.2)));
  const [name, setName] = useState('');
  const [showDescription, setShowDescription] = useState(false);
  const [localParticipants, setLocalParticipants] = useState<NormalizedExternalParticipant[]>([]);
  const [questionAnswers, setQuestionAnswers] = useState<{questionId: number, question: string, 
    answer: string}[]>([]);
  const [logoLoaded, setLogoLoaded] = useState(false);
  
  const containerRef = useRef<HTMLDivElement>(null);
  const stepBreakpoint = useElementBreakpoint(containerRef, {790: "xs", 791: "sm", 1100: "md", 1101: "lg"}, 'lg');

  const isLgDown = ["xs", "sm", "md"].some(bp => bp === stepBreakpoint);
  const isSmDown = ["xs"].some(bp => bp === stepBreakpoint);
  const durationText = humanReadableDuration(meetingInfo?.duration_minutes || 0);
  const drawerWidthStr = drawerWidth + 'px';

  const meetingTitle = replaceTemplateVariables(
    meetingInfo?.title_booked || meetingInfo?.title || '', name, questionAnswers);
  const templateVars = getTemplateVars(meetingInfo?.questions?.map((q) => ({ [q.id]: q }))
    .reduce((a, b) => ({ ...a, ...b }), {}));

  const meetingDescription = replaceTemplateVariables(
    meetingInfo?.description_booked || meetingInfo?.description || '', name, questionAnswers);

  const from = meetingInfo?.meeting_organizer_override && meetingInfo.meeting_organizer_override !== ''
    ?  meetingInfo.meeting_organizer_override 
    : `${meetingInfo?.create_user.first_name} ${meetingInfo?.create_user.last_name}`; 
  

  const CompanyLogo = styled('img', { label: "CompanyLogo" })(
    {
      fontSize: 24,
      maxWidth: 250,
      maxHeight: 74,
      marginBottom: 5,
      justifyContent: 'flex-end',
      marginTop: 5,
      border: `2px solid ${colors.black500}`,
      borderRadius: 6,
      padding: 8,
      backgroundColor: colors.white900
    }
  );

  useEffect(() => {
    function handleResize() {
      setDrawerWidth(Math.min(Math.max(300, (window.innerWidth * 0.2)), 450));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const Step = useMemo(() => {
    const InitialStep = isSmDown ?
      <MobileDateTimeSelection
        selectedDay={selectedDay}
        orderedAvailableDates={orderedAvailableDates}
        currentTimezone={currentTimezone}
        slotSelected={slotSelected}
        from={from}
        isPreview={isPreview}
        setCurrentTimeZone={setCurrentTimeZone}
        handleDaySelected={handleDaySelected}
        slotsByDay={slotsByDay}
        setSlotSelected={setSlotSelected}
        onConfirm={onConfirm}
        isSelectedDay={isSelectedDay}
        isFramed={isFramed}
        onCalendarDateChange={onCalendarDateChange}
        loading={loading}
        defaultYearMonth={defaultYearMonth}
        setNoTimesWork={setNoTimesWork}
        showSkeletons={loadingPage}
      />
      :
      <DateTimeSelection
        selectedDay={selectedDay}
        orderedAvailableDates={orderedAvailableDates}
        currentTimezone={currentTimezone}
        from={from}
        slotSelected={slotSelected}
        isPreview={isPreview}
        setCurrentTimeZone={setCurrentTimeZone}
        handleDaySelected={handleDaySelected}
        slotsByDay={slotsByDay}
        setSlotSelected={setSlotSelected}
        onConfirm={onConfirm}
        hideTitle={isLgDown}
        isFramed={isFramed}
        onCalendarDateChange={onCalendarDateChange}
        loading={loading}
        defaultYearMonth={defaultYearMonth}
        viewDate={viewDate}
        isEnterpriseBook={isEnterpriseBook}
        setNoTimesWork={setNoTimesWork}
        showSkeletons={loadingPage}
      />;

    const remainingSteps = meetingInfo ? (
      [
        <AttendeeInfo
          key="attendeeInfo"
          selectedDay={selectedDay}
          currentTimezone={currentTimezone}
          slotSelected={slotSelected}
          meeting={meetingInfo}
          isBooking={isBooking}
          setName={setName}
          setQuestionAnswers={setQuestionAnswers}
          handleScheduleMeeting={handleScheduleMeeting}
          fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
          isReschedulingURL={isReschedulingURL}
          setLocalParticipants={setLocalParticipants}
          allowAddParticipants={allowAddParticipants}
          isPreview={isPreview}
          prefillEmail={prefillEmail}
          prefillName={prefillName}
          rebookParticipants={rebookParticipants}
          rebookAnswers={rebookAnswers}
        />,
        <Confirmation
          key="confirmation"
          selectedDay={selectedDay}
          slotSelected={slotSelected}
          meeting={meetingInfo}
          currentTimezone={currentTimezone}
          fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
          localParticipants={localParticipants}
          confirmed={confirmed}
          isBadResponse={isBadResponse}
          noTimesWork={noTimesWork}
          noTimesWorkSent={noTimesWorkSent}
        />,
        <NoTimesWork
          key="no_times_work"
          meeting={meetingInfo}
          isBooking={isBooking}
          isReschedulingURL={isReschedulingURL}
          isPreview={isPreview}
          prefillEmail={prefillEmail}
          prefillName={prefillName}
          onSubmit={submitNoTimesWorkForm}
        />
      ]
    ) : ( [
      <></>
    ]
    );
    return [
      InitialStep,
      ...remainingSteps
    ][step];
  }, [
    isSmDown, selectedDay, orderedAvailableDates, currentTimezone, slotSelected, meetingInfo, isPreview,
    setCurrentTimeZone, handleDaySelected, slotsByDay, setSlotSelected, onConfirm, isSelectedDay, isFramed,
    onCalendarDateChange, loading, defaultYearMonth, setNoTimesWork, isLgDown, viewDate, isEnterpriseBook,
    isBooking, handleScheduleMeeting, fetchMeetingQuestionAnswers, isReschedulingURL, allowAddParticipants,
    prefillEmail, prefillName, rebookParticipants, rebookAnswers, localParticipants, confirmed, isBadResponse, step,
    submitNoTimesWorkForm, noTimesWork, noTimesWorkSent, from, loadingPage
  ]);

  const logo = meetingInfo ? meetingInfo.create_user.active_license?.organization.logo || NAVY_LOGO_SRC : '';

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box ref={containerRef} display={isEnterpriseBook ? 'flex' : undefined} 
        justifyContent={isEnterpriseBook ? 'center' : 'inherit'}
        sx={{ height: '100%', width: '100%', backgroundColor: isEnterpriseBook ? 'unset' : colors.greyBackdrop }} 
        maxHeight={'100vh'} overflow='auto' data-testid="book-meeting-container" paddingTop={isFramed ? 4 : 0}>
        {!isLgDown && !isEnterpriseBook && (
          <SidePanel
            logo={logo}
            showSkeletons={loadingPage}
            name={ meetingInfo ? (
              <Box>
                <CabTextTokenDisplay
                  chipSx={{fontSize: '22px', padding: '12px'}}
                  templateVars={templateVars}
                >
                  {meetingTitle || meetingInfo.title_booked || meetingInfo.title}
                </CabTextTokenDisplay>
              </Box>
            ) : (
              <></>
            )}
            description={ meetingInfo ? (
              <Box>
                <CabTextTokenDisplay
                  chipSx={{fontSize: '14px', padding: '10px'}}
                  templateVars={templateVars}
                >
                  {meetingDescription || meetingInfo.description_booked || meetingInfo?.description || ''}
                </CabTextTokenDisplay>
              </Box>
            ) : (
              <></>
            )}
            durationText={durationText}
            leaders={meetingInfo?.leaders || []}
            meetingId={ isAuthenticated ? meetingInfo?.id : undefined}
          />
        )}
        <Box marginLeft={(isLgDown || isEnterpriseBook) ? 0 : drawerWidthStr} padding={isSmDown ? 3 : isLgDown ? 4 : 6}>
          {step === 1 && (
            <Box 
              display="flex" 
              marginTop={isLgDown ? 0 : -3} 
              marginBottom={isSmDown ? 1 : 3} 
              justifyContent={'flex-start'}
              alignItems='center'
            >
              <CabButton
                onClick={() => setStep(step - 1)}
                icon={<CabIcon Icon={IoArrowBack} />}
                buttonType="tertiary"
                color="accent"
                size="small"
                sx={{height: 30}}
              >
                Change times
              </CabButton>
            </Box>
          )}
          <Box display='flex' width='100%' justifyContent='flex-start'>
            {step === 0 && isLgDown && !isEnterpriseBook && (
              <Box>
                {!isFramed &&
                loadingPage ? (
                    <Box width='100%' marginBottom={1}>
                      <Skeleton height={70} width='100%' variant='rounded' />
                    </Box>
                  ) : (
                    <CompanyLogo src={logo} alt=''
                      {...(logoLoaded ? {} : { width: "100%", height: 74 })}
                      onLoad={() => setLogoLoaded(true)}
                    />
                  )
                }
                <Box display='flex' flexDirection='column' gap={4}>
                  <Box display='flex' flexDirection='column' gap={1}>
                    {!isFramed && (
                      <Box display="flex">
                        {loadingPage ? (
                          <Skeleton variant='rounded' width={120} height={30} />
                        ) : (
                          <Chip
                            sx={{ borderRadius: '6px', fontWeight: 500 }}
                            icon={<CabIcon Icon={IoTimeOutline} alt='duration' sx={{fontSize: '16px'}}/>}
                            label={durationText}
                          />
                        )}
                      </Box>
                    )}
                    <Typography variant="h1">
                      {loadingPage ? (
                        <Skeleton width='100%' height={32} variant='rounded' />
                      ) : (
                        <Box marginBottom={1}>
                          {meetingInfo ? (
                            <CabTextTokenDisplay
                              chipSx={{fontSize: '22px', padding: '12px'}}
                              templateVars={templateVars}
                            >
                              {meetingInfo.title || ''}
                            </CabTextTokenDisplay>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                    </Typography>
                    {!isFramed && (
                      <Box>
                        {meetingInfo?.description && (
                          <>
                            <CabButton buttonType='tertiary' color='accent' 
                              onClick={() => setShowDescription(!showDescription)}
                              icon={<CabIcon Icon={showDescription ? IoEyeOffOutline : IoEyeOutline}/>} 
                              sx={{width: '178px'}}
                            >
                              {showDescription ? 'Hide Description' : 'See Description'}
                            </CabButton>
                            {showDescription && (
                              <Typography whiteSpace='pre-wrap' variant="body1">{meetingInfo.description}</Typography>
                            )}
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {Step}
          {step === 2 && meetingInfo && organization && (
            <Box marginTop={4}>
              <PollResponseCTA
                organizationId={organization.id}
                organizationName={organization.name}
                userId={meetingInfo.create_user.id}
                meetingId={meetingInfo.id}
                isAuthenticated={isAuthenticated}
                event={EVENT_TYPE.MEETING_BOOKED_SIGNUP} />
            </Box>
          )}
        </Box>
      
      
      </Box>
      {meetingInfo?.id && !isEnterpriseBook && isLgDown && isAuthenticated && (
        <Box display='flex' padding={2} 
          sx={{ backgroundColor: isEnterpriseBook ? 'unset' : colors.greyBackdrop }}>
          <Typography variant="body2">Are you the meeting owner? &nbsp;</Typography>
          <Link component={RouterLink} to={`${PAGE_URL.SCHEDULE}/${meetingInfo.id}`} variant="body2" 
            color={colors.black900} fontWeight={500}
          >
            Click here
          </Link>
          <Typography variant="body2">&nbsp;to make changes.</Typography>
        </Box>
      )}
    </Box>
  );
};

export type PageProps = {
  isAuthenticated: boolean;
  step: number;
  meetingInfo: ExternalMeetingInfo | null;
  selectedDay: DateTime | null;
  orderedAvailableDates: DateTime[];
  slotsByDay: BookingSlotsByDay;
  currentTimezone: string;
  slotSelected: BookingSlot | null;
  isPreview?: boolean;
  isReschedulingURL?: boolean;
  isCancelingURL?: boolean;
  isBooking: boolean;
  isBadResponse: boolean;
  confirmed: boolean;
  allowAddParticipants: boolean;
  organization?: PublicOrganization;
  isSelectedDay?: boolean;
  setCurrentTimeZone: (tz: string) => void;
  handleDaySelected: (daySelected: DateTime | null) => void;
  setSlotSelected: (slot: BookingSlot) => void;
  onConfirm: () => void;
  setStep: (step: number) => void
  handleScheduleMeeting: (externalParticipants: NormalizedExternalParticipant[], 
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => void;
  handleCancelMeeting: (message: string) => Promise<FetchReturn | undefined>;
  fetchMeetingQuestionAnswers: (externalId?: string) => Promise<{
    answers: {[id: number]: MeetingQuestionAnswer}} | undefined>
  prefillName?: string;
  prefillEmail?: string;
  isFramed?: boolean;
  onCalendarDateChange?: (date: DateTime) => void
  loading: boolean;
  defaultYearMonth: [number, number]|null;
  rebookParticipants: NormalizedExternalParticipant[];
  rebookAnswers: MeetingQuestionAnswerSubmission[];
  viewDate: DateTime;
  noCabPage?: boolean;
  isEnterpriseBook?: boolean;
  setNoTimesWork: (value: boolean) => void
  submitNoTimesWorkForm: (data: NormalizedExternalParticipant) => void
  noTimesWork: boolean
  noTimesWorkSent: boolean
  loadingCalendar: boolean;
};

const BookMeetingPage = ({
  isAuthenticated, step, meetingInfo, selectedDay, orderedAvailableDates, slotsByDay, currentTimezone, 
  isPreview, slotSelected, isBooking, isReschedulingURL, setCurrentTimeZone, handleDaySelected, setSlotSelected, 
  onConfirm, setStep, handleScheduleMeeting, fetchMeetingQuestionAnswers, isCancelingURL, organization, 
  handleCancelMeeting, allowAddParticipants, confirmed, isBadResponse, isSelectedDay, prefillEmail, prefillName, 
  isFramed, onCalendarDateChange, loading, defaultYearMonth, rebookParticipants, rebookAnswers, viewDate, noCabPage,
  isEnterpriseBook, setNoTimesWork, submitNoTimesWorkForm, noTimesWork, noTimesWorkSent, loadingCalendar
}: PageProps): ReactElement => {

  if (noCabPage || isPreview) {
    return (
      <Box width='100%'>
        {meetingInfo && organization
          ? <BookMeeting
            meetingInfo={meetingInfo}
            isAuthenticated={isAuthenticated}
            selectedDay={selectedDay}
            orderedAvailableDates={orderedAvailableDates}
            currentTimezone={currentTimezone}
            step={step}
            slotSelected={slotSelected}
            isPreview={isPreview}
            isReschedulingURL={isReschedulingURL}
            isBooking={isBooking}
            setCurrentTimeZone={setCurrentTimeZone}
            handleDaySelected={handleDaySelected}
            slotsByDay={slotsByDay}
            setSlotSelected={setSlotSelected}
            onConfirm={onConfirm}
            setStep={setStep}
            handleScheduleMeeting={handleScheduleMeeting}
            fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
            allowAddParticipants={allowAddParticipants}
            confirmed={confirmed}
            isBadResponse={isBadResponse}
            isSelectedDay={isSelectedDay}
            prefillEmail={prefillEmail}
            prefillName={prefillName}
            isFramed={isFramed}
            onCalendarDateChange={onCalendarDateChange}
            loading={loadingCalendar}
            defaultYearMonth={defaultYearMonth}
            rebookParticipants={rebookParticipants}
            rebookAnswers={rebookAnswers}
            viewDate={viewDate}
            organization={organization}
            isEnterpriseBook={isEnterpriseBook}
            setNoTimesWork={setNoTimesWork}
            submitNoTimesWorkForm={submitNoTimesWorkForm}
            noTimesWork={noTimesWork}
            noTimesWorkSent={noTimesWorkSent}
            loadingPage={loading}
          />
          : 
          <Box hidden={loading} display='flex' justifyContent='center' marginTop={5}>
            <Alert severity="warning">
              No meeting was found at this link
            </Alert>
          </Box>
        }
        <Box hidden={!loading} height='100%' display='flex' alignItems='center' data-testid="book-meeting-spinner">
          <CabSpinner scale={4} color='inherit' />
        </Box>
      </Box>
    );
  }

  return (
    <CabinetPage
      pageName={'Book Meeting'}
      noHeader
    >
      {isCancelingURL ? (
        meetingInfo && organization ? (
          <MeetingCancelation
            meeting={meetingInfo}
            dateSet={slotSelected}
            currentTimeZone={currentTimezone}
            leaders={meetingInfo.leaders}
            organization={organization}
            name={''}
            confirmed={true}
            handleCancelMeeting={handleCancelMeeting}
          />
        ) : (
          <Box hidden={!loading} height='100%' display='flex' alignItems='center' data-testid="book-meeting-spinner">
            <CabSpinner scale={4} color='inherit' />
          </Box>
        )
      ) : (
        (loading || meetingInfo) ? (
          <BookMeeting
            meetingInfo={meetingInfo || undefined}
            isAuthenticated={isAuthenticated}
            selectedDay={selectedDay}
            orderedAvailableDates={orderedAvailableDates}
            currentTimezone={currentTimezone}
            step={step}
            slotSelected={slotSelected}
            isPreview={isPreview}
            isReschedulingURL={isReschedulingURL}
            isBooking={isBooking}
            setCurrentTimeZone={setCurrentTimeZone}
            handleDaySelected={handleDaySelected}
            slotsByDay={slotsByDay}
            setSlotSelected={setSlotSelected}
            onConfirm={onConfirm}
            setStep={setStep}
            handleScheduleMeeting={handleScheduleMeeting}
            fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
            allowAddParticipants={allowAddParticipants}
            confirmed={confirmed}
            isBadResponse={isBadResponse}
            isSelectedDay={isSelectedDay}
            prefillEmail={prefillEmail}
            prefillName={prefillName}
            isFramed={isFramed}
            onCalendarDateChange={onCalendarDateChange}
            loading={loadingCalendar}
            defaultYearMonth={defaultYearMonth}
            rebookParticipants={rebookParticipants}
            rebookAnswers={rebookAnswers}
            viewDate={viewDate}
            organization={organization}
            setNoTimesWork={setNoTimesWork}
            submitNoTimesWorkForm={submitNoTimesWorkForm}
            noTimesWork={noTimesWork}
            noTimesWorkSent={noTimesWorkSent}
            loadingPage={loading}
          />
        ) : (
          <Box display='flex' justifyContent='center' marginTop={5}>
            <Alert severity="warning">
              No meeting was found at this link
            </Alert>
          </Box>
        )
      )}
      {/* <Box hidden={!loading} height='100%' display='flex' alignItems='center' data-testid="book-meeting-spinner">
        <CabSpinner scale={4} color='inherit' />
      </Box> */}
    </CabinetPage>
  );
};

export default BookMeetingPage;